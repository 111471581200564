import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../index";
import Loader from "../ui/loader/loader";
import PeopleService from "../services/peopleService";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import BottomMenu from "../ui/menu/bottomMenu";
import Popup from "../ui/popup/popup";
import Hr from "../ui/hr/hr";
import Button from "../ui/button/button";
import Select from "../ui/select/select";
import CompanyService from "../services/companyService";
import CategoryService from "../services/categoryService";
import {observer} from "mobx-react-lite";

const PeopleOneComponent = observer(() => {
    const {id} = useParams();
    const {people, company, category} = React.useContext(Context);
    const navigate = useNavigate()
    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [edit, setEdit] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const [name, setName] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');
    const [categoryId, setCategoryId] = React.useState('');

    const loadData = async (id) => {
        const [peopleResult, companyResult, categoryResult] = await Promise.allSettled([
            PeopleService.getPeople(id),
            CompanyService.getCompanies(),
            CategoryService.getCategories()
        ]);
        people.setPeople(peopleResult.value);
        company.setCompanies(companyResult.value);
        category.setCategories(categoryResult.value);
    }

    React.useEffect(() => {
        try{
            loadData(id)
                .then()
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoader(false))
        } catch (err) {
            setError(err)
        }

    }, [id])

    const peopleEdit = () => {
        setEdit(true);
        setName(people.isPeople?.name);
        setCompanyId(people.isPeople?.companyId);
        setCategoryId(people.isPeople?.categoryId);
    }

    const canselEdit = () => {
        setEdit(false);
        setName('');
        setCompanyId('');
        setCategoryId('');
    }

    const saveEdit = () => {
        setLoader(true);
        PeopleService.editPeople(id, name, companyId, categoryId)
            .then(() => navigate(-1))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))

    }

    const openPopup = () => {
        setPopup(true);
    }

    const closePopup = () => {
        setPopup(false);
    }

    const deletePeople = async () => {
        setLoader(true)
        PeopleService.removePeople(id)
            .then(() => navigate('/peoples'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex jc-center">
                    {
                        !edit
                            ?
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                type={'add'}
                                buttonText={'Изменить'}
                                buttonBackText={'Назад'}
                                next={peopleEdit}
                                back={() => navigate(-1)}
                            >
                                <div className="name">
                                    <img style={{width: '100%'}} src={process.env.REACT_APP_API_URL_STATIC + `/img/${people.isPeople?.company?.name}/${people.isPeople?.img}`} alt={people.isPeople?.name}/>
                                </div>
                                <div className="name">
                                    <span>Имя:</span>
                                    <p>{people.isPeople?.name}</p>
                                </div>
                                <div className="phone">
                                    <span>Компания:</span>
                                    <p>{people.isPeople?.company?.name}</p>
                                </div>
                                <div className="email">
                                    <span>Категория:</span>
                                    <p>{people.isPeople?.category?.name}</p>
                                </div>
                            </Cart>
                            :
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                variantDel={'linkText'}
                                type={'delete'}
                                buttonText={'Сохранить'}
                                buttonBackText={'Отмена'}
                                buttonDelText={'Удалить'}
                                next={saveEdit}
                                back={canselEdit}
                                del={openPopup}
                            >
                                <div className="name">
                                    <img style={{width: '100%', marginBottom: '8px'}} src={process.env.REACT_APP_API_URL_STATIC + `/img/${people.isPeople?.company.name}/${people.isPeople?.img}`} alt={people.isPeople?.name}/>
                                </div>
                                <div className="name">
                                    <span>Имя:</span>
                                    <p>{people.isPeople?.name}</p>
                                </div>
                                {/*<div className="name">*/}
                                {/*    <span>Имя:</span>*/}
                                {/*    <Input value={name} onChange={event => setName(event.target.value)}/>*/}
                                {/*</div>*/}
                                <div className="phone">
                                    <span>Компания:</span>
                                    <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                        {
                                            company.isCompanies?.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                        }
                                    </Select>
                                </div>
                                <div className="email">
                                    <span>Категория:</span>
                                    <p>{people.isPeople?.category.name}</p>
                                </div>
                                {/*<div className="email">*/}
                                {/*    <span>Категория:</span>*/}
                                {/*    <Select value={categoryId} onChange={event => setCategoryId(event.target.value)}>*/}
                                {/*        {*/}
                                {/*            category.isCategories?.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)*/}
                                {/*        }*/}
                                {/*    </Select>*/}
                                {/*</div>*/}
                            </Cart>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
            {
                popup &&
                <Popup>
                    <div style={{marginTop: '72px', flexDirection: 'column'}} className='flex jc-center ai-center'>
                        <Cart>
                            <h3 style={{marginBottom: '16px'}}>Вы точно хотите удалить сотрудника: {name}?</h3>
                            <Hr/>
                            <Button onClick={deletePeople} style={{width: '100%'}} variant={'linkText'}>Да</Button>
                            <Button onClick={closePopup} style={{width: '100%', marginTop: '16px'}}  variant={'link'}>Отмена</Button>
                        </Cart>
                    </div>
                </Popup>
            }
        </div>
    );
});

export default PeopleOneComponent;