import {$authHost} from "../http/axios";

export default class JournalService{
    static getJournals = async (page, limit) => {
        const {data} = await $authHost.get('/journal', {params: {limit, page}});
        return data;
    }

    static addJournal = async (img, imgCheck, name, company, movement, photo, url, status) => {
        const {data} = await $authHost.post('/journal', {img, imgCheck, name, company, movement, photo, url, status});
        return data;
    }

    static filterJournal = async (company) => {
        const {data} = await $authHost.get(`/journal`, {params: {body: {company: company}}});
        return data;
    }

    static searchJournal = async (name) => {
        const {data} = await $authHost.get(`/journal`, {params: {body: {name: name}}});
        return data;
    }

    static removeJournal = async () => {
        const {data} = await $authHost.delete(`/journal`);
        return data;
    }
}