import React from 'react';
import {Context} from "../index";
import Loader from "../ui/loader/loader";
import UserService from "../services/userService";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import {useNavigate} from "react-router-dom";
import BottomMenu from "../ui/menu/bottomMenu";
import Input from "../ui/input/input";
import {observer} from "mobx-react-lite";
import jwt_decode from "jwt-decode";

const ProfileComponent = observer(() => {
    const {user} = React.useContext(Context);
    const navigate = useNavigate();

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [edit, setEdit] = React.useState(false);
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');

    React.useEffect(() => {
        try{
            UserService.getUser(`${jwt_decode(localStorage.getItem('token')).id}`)
                .then(data => user.setUser(data))
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoader(false))
        } catch (err) {
            setError(err.response.data.message);
        }
    }, [user]);

    const profileEdit = () => {
        setEdit(true);
        setName(user.isUser?.name);
        setPhone(user.isUser?.phone);
    }

    const canselEdit = () => {
        setEdit(false);
        setName('');
        setPhone('');
    }

    const saveEdit = () => {
        setLoader(true);
        UserService.editUser(user.isUser.id, name, user.isUser?.email, phone, user.isUser?.roleId, user.isUser?.companyId)
            .then(() => {
                canselEdit()
                UserService.getUser(`${user.isUser.id}`)
                    .then(data => {
                        user.setUser(data);
                    })
                    .catch(err => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setLoader(false);
                    })
            })
            .catch(err => setError(err.response.data.message))
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex jc-center">
                    {
                        !edit
                            ?
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                type={'add'}
                                buttonText={'Изменить'}
                                buttonBackText={'Отмена'}
                                next={profileEdit}
                                back={() => navigate(-1)}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <p>{user.isUser?.name}</p>
                                </div>
                                <div className="email">
                                    <span>Email:</span>
                                    <p>{user.isUser?.email}</p>
                                </div>
                                <div className="phone">
                                    <span>Телефон:</span>
                                    <p>{user.isUser?.phone}</p>
                                </div>
                                <div className="company">
                                    <span>Компания:</span>
                                    <p>{user.isUser.company?.name}</p>
                                </div>
                                <div className="role">
                                    <span>Роль:</span>
                                    <p>{user.isUser.role?.name}</p>
                                </div>
                            </Cart>
                            :
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                type={'add'}
                                buttonText={'Сохранить'}
                                buttonBackText={'Отмена'}
                                next={saveEdit}
                                back={canselEdit}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <Input value={name} onChange={event => setName(event.target.value)}/>
                                </div>
                                <div className="email">
                                    <span>Email:</span>
                                    <p>{user.isUser.email}</p>
                                </div>
                                <div className="phone">
                                    <span>Телефон:</span>
                                    <Input value={phone} onChange={event => setPhone(event.target.value)}/>
                                </div>
                                <div className="company">
                                    <span>Компания:</span>
                                    <p>{user.isUser.company?.name}</p>
                                </div>
                                <div className="role">
                                    <span>Роль:</span>
                                    <p>{user.isUser.role?.name}</p>
                                </div>
                            </Cart>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
        </div>
    );
});

export default ProfileComponent;