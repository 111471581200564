import React from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Context} from "../index";
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import {observer} from "mobx-react-lite";
import CameraService from "../services/cameraService";
import Input from "../ui/input/input";
import Select from "../ui/select/select";
import LocationService from "../services/locationService";
import BottomMenu from "../ui/menu/bottomMenu";
import Popup from "../ui/popup/popup";
import Button from "../ui/button/button";
import Hr from "../ui/hr/hr";
import jwt_decode from "jwt-decode";

const CameraOneComponent = observer(() => {
    const {id} = useParams();
    const {camera, location} = React.useContext(Context);
    const navigate = useNavigate()
    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [edit, setEdit] = React.useState(false);
    const [name, setName] = React.useState('');
    const [rele, setRele] = React.useState('');
    const [movement, setMovement] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [accuracy, setAccuracy] = React.useState('');
    const [locationId, setLocationId] = React.useState('');
    const [popup, setPopup] = React.useState(false);

    const loadData = async (id) => {
        const [cameraResult, locationResult] = await Promise.allSettled([CameraService.getCamera(id), LocationService.getLocations()]);
        camera.setCamera(cameraResult.value);
        location.setLocations(locationResult.value)
    }

    React.useEffect(() => {
        loadData(id)
            .then()
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }, [])

    const cameraEdit = () => {
        setEdit(true);
        setName(camera.isCamera?.name);
        setRele(camera.isCamera?.rele);
        setMovement(camera.isCamera?.movement);
        setUrl(camera.isCamera?.url);
        setAccuracy(camera.isCamera?.accuracy);
        setLocationId(camera.isCamera?.locationId);
    }

    const canselEdit = () => {
        setEdit(false);
        setName('');
        setRele('');
        setMovement('');
        setUrl('');
        setAccuracy('');
        setLocationId('');
    }

    const saveEdit = () => {
        setLoader(true);
        CameraService.editCamera(id, name, rele, movement, url, accuracy, locationId)
            .then(() => navigate(-1))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    const openPopup = () => {
        setPopup(true);
    }

    const closePopup = () => {
        setPopup(false);
    }

    const deleteCamera = async () => {
        setLoader(true)
        CameraService.removeCamera(id)
            .then(() => navigate('/cameras'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex jc-center">
                    {
                        !edit
                            ?
                                jwt_decode(localStorage.getItem('token')).roleId !== 5
                                ?
                                    <Cart
                                        variant={'link'}
                                        variantBack={'outline'}
                                        type={'add'}
                                        buttonText={'Изменить'}
                                        buttonBackText={'Назад'}
                                        next={cameraEdit}
                                        back={() => navigate(-1)}
                                    >
                                        <div className="name">
                                            <span>Имя:</span>
                                            <p>{camera.isCamera?.name}</p>
                                        </div>
                                        <div className="name">
                                            <span>Реле:</span>
                                            <p>{camera.isCamera?.rele}</p>
                                        </div>
                                        <div className="name">
                                            <span>Движение:</span>
                                            <p>{camera.isCamera?.movement}</p>
                                        </div>
                                        <div className="name">
                                            <span>Адрес:</span>
                                            <p>{camera.isCamera?.url}</p>
                                        </div>
                                        <div className="name">
                                            <span>Разница:</span>
                                            <p>{camera.isCamera?.accuracy}</p>
                                        </div>
                                        <div className="name">
                                            <span>Локация:</span>
                                            <p>{camera.isCamera?.location.name}</p>
                                        </div>
                                        <div className="name">
                                            <span>Распознавание:</span>
                                            <Link to={'/camera/' + camera.isCamera.url}>Перейти</Link>
                                        </div>
                                    </Cart>
                                :
                                    <Cart
                                        variant={'add'}
                                        variantBack={'outline'}
                                        type={'add'}
                                        buttonBackText={'Назад'}
                                        back={() => navigate(-1)}
                                    >
                                        <div className="name">
                                            <span>Имя:</span>
                                            <p>{camera.isCamera?.name}</p>
                                        </div>
                                        <div className="name">
                                            <span>Движение:</span>
                                            <p>{camera.isCamera?.movement}</p>
                                        </div>
                                        <div className="name">
                                            <span>Локация:</span>
                                            <p>{camera.isCamera?.location.name}</p>
                                        </div>
                                        <div className="name">
                                            <span>Распознавание:</span>
                                            <Link to={'/camera/' + camera.isCamera.url}>Перейти</Link>
                                        </div>
                                    </Cart>
                            :
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                variantDel={'linkText'}
                                type={'delete'}
                                buttonText={'Сохранить'}
                                buttonBackText={'Отмена'}
                                buttonDelText={'Удалить'}
                                next={saveEdit}
                                back={canselEdit}
                                del={openPopup}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <Input value={name} onChange={event => setName(event.target.value)}/>
                                </div>
                                <div className="name">
                                    <span>Реле:</span>
                                    <Input value={rele} onChange={event => setRele(event.target.value)}/>
                                </div>
                                <div className="name">
                                    <span>Движение:</span>
                                    <Input value={movement} onChange={event => setMovement(event.target.value)}/>
                                </div>
                                <div className="name">
                                    <span>Адрес:</span>
                                    <Input value={url} onChange={event => setUrl(event.target.value)}/>
                                </div>
                                <div className="name">
                                    <span>Разница:</span>
                                    <Input value={accuracy} onChange={event => setAccuracy(event.target.value)}/>
                                </div>

                                <div className="name">
                                    <span>Локация:</span>
                                    <Select value={locationId} onChange={event => setLocationId(event.target.value)}>
                                        {
                                            location.isLocations?.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                        }
                                    </Select>
                                </div>
                                <div className="name">
                                    <span>Распознавание:</span>
                                    <Link to={'/camera/' + camera.url}>Перейти</Link>
                                </div>

                            </Cart>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
            {
                popup &&
                <Popup>
                    <div style={{marginTop: '72px', flexDirection: 'column'}} className='flex jc-center ai-center'>
                        <Cart>
                            <h3 style={{marginBottom: '16px'}}>Вы точно хотите удалить камеру: {name}?</h3>
                            <Hr/>
                            <Button onClick={deleteCamera} style={{width: '100%'}} variant={'linkText'}>Да</Button>
                            <Button onClick={closePopup} style={{width: '100%', marginTop: '16px'}}  variant={'link'}>Отмена</Button>
                        </Cart>
                    </div>
                </Popup>
            }
        </div>
    );
});

export default CameraOneComponent;