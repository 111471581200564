import React from 'react';
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import Select from "../ui/select/select";
import {useNavigate} from 'react-router-dom';
import Input from "../ui/input/input";
import RoleService from "../services/roleService";
import CompanyService from "../services/companyService";
import UserService from "../services/userService";
import LocationService from "../services/locationService";
import CameraService from "../services/cameraService";
import BottomMenu from "../ui/menu/bottomMenu";
import Button from "../ui/button/button";
import * as faceapi from "face-api.js";
import PeopleService from "../services/peopleService";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import jwt_decode from "jwt-decode";
import Error from "../ui/error/error";

const AddComponent = observer(() => {

    const {user} = React.useContext(Context);

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [select, setSelect] = React.useState('');
    const [addUser, setAddUser] = React.useState(false);
    const [addEmployee, setAddEmployee] = React.useState(false);
    const [addGuest, setAddGuest] = React.useState(false);
    const [addWatch, setAddWatch] = React.useState(false);
    const [addCompany, setAddCompany] = React.useState(false);
    const [addLocation, setAddLocation] = React.useState(false);
    const [addCamera, setAddCamera] = React.useState(false);
    const [videoPlay, setVideoPlay] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [uData, setUData] = React.useState({});
    const navigate = useNavigate()

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [roleId, setRoleId] = React.useState('')
    const [companyId, setCompanyId] = React.useState('')
    const [rele, setRele] = React.useState('')
    const [movement, setMovement] = React.useState('')
    const [url, setUrl] = React.useState('')
    const [accuracy, setAccuracy] = React.useState('')
    const [locationId, setLocationId] = React.useState('')
    const [companyName, setCompanyName] = React.useState('')
    const [userData, setUserData] = React.useState(null)
    const [photoCaptured, setPhotoCaptured] = React.useState(false)
    const inputFileRef = React.useRef(null);
    const imgInput = React.useRef(null);
    const webcamRef = React.useRef(null);
    const canvasRef = React.useRef(null);

    const videoConstraints = {
        width: 372,
        height: 496,
        facingMode: "user"
    };

    const loadData = async () => {
        const [userResult, roleResult, locationResult, companyResult] = await Promise.allSettled([
            UserService.getUser(jwt_decode(localStorage.getItem('token')).id),
            RoleService.getRoles(),
            LocationService.getLocations(),
            CompanyService.getCompanies()
        ])
        setUData(userResult.value);
        setRoles(roleResult.value);
        setLocations(locationResult.value);
        setCompanies(companyResult.value);
    }

    React.useEffect(() => {
        loadData()
            .then(() => {
                if (uData.roleId === 3){
                    console.log(uData.company.name)
                    setCompanyId(String(uData.companyId))
                    setCompanyName(uData.company.name)
                }
            })
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }, [uData.roleId])

    const loadModels = async () => {
        setLoader(true);
        const MODEL_URL = process.env.PUBLIC_URL + '/models';
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        ])
            .finally(() => {
                setLoader(false);
            });
    }

    const loadFileInput = () => {
        const reader = new FileReader()
        reader.onload = (e) => {
            setUserData(e.target.result)
        }
        reader.readAsDataURL(inputFileRef.current.files[0])
        setPhotoCaptured(true);
    }

    const nextStep = () => {
        if (select === '0'){
            setAddUser(true);
            setAddEmployee(false);
            setAddGuest(false);
            setAddWatch(false);
            setAddCompany(false);
            setAddLocation(false);
            setAddCamera(false);

        } else if (select === '1'){
            loadModels().then();
            setAddEmployee(true);
            setAddUser(false);
            setAddGuest(false);
            setAddWatch(false);
            setAddCompany(false);
            setAddLocation(false);
            setAddCamera(false);
        } else if (select === '2'){
            loadModels().then();
            setAddGuest(true);
            setAddUser(false);
            setAddEmployee(false);
            setAddWatch(false);
            setAddCompany(false);
            setAddLocation(false);
            setAddCamera(false);
        } else if (select === '3'){
            loadModels().then();
            setAddWatch(true);
            setAddUser(false);
            setAddEmployee(false);
            setAddGuest(false);
            setAddCompany(false);
            setAddLocation(false);
            setAddCamera(false);
        } else if (select === '4'){
            setAddCompany(true);
            setAddUser(false);
            setAddEmployee(false);
            setAddGuest(false);
            setAddWatch(false);
            setAddLocation(false);
            setAddCamera(false);
        } else if (select === '5'){
            setAddLocation(true);
            setAddUser(false);
            setAddEmployee(false);
            setAddGuest(false);
            setAddWatch(false);
            setAddCompany(false);
            setAddCamera(false);
        } else if (select === '6'){
            setAddCamera(true);
            setAddUser(false);
            setAddEmployee(false);
            setAddGuest(false);
            setAddWatch(false);
            setAddCompany(false);
            setAddLocation(false);
        }
    }

    const addUserInDb = async () => {
        setLoader(true);
        UserService.addUser(name, email, roleId, companyId)
            .then(() => navigate('/users'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    const addCompanyInDb = async () => {
        setLoader(true);
        CompanyService.addCompany(name)
            .then(() => navigate('/companies'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    const addLocationInDb = async () => {
        setLoader(true);
        LocationService.addLocation(name)
            .then(() => navigate('/locations'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    const addCameraInDb = async () => {
        setLoader(true);
        CameraService.addCamera(name, rele, movement, url, accuracy, locationId)
            .then(() => navigate('/cameras'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    const faceMatcher = async (data) => {
        try {
            faceapi.matchDimensions(data.current, {width: videoConstraints.width, height: videoConstraints.height});
            const detections = await faceapi.detectAllFaces(data.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions().withFaceDescriptors();
            const resizedDetections = faceapi.resizeResults(detections, {width: videoConstraints.width, height: videoConstraints.height});
            return resizedDetections[0].descriptor
        } catch (err) {
            setError('Не могу обнаружить лицо на фотографии');
            setTimeout(() => {
                setError('');
                navigate(-1)
            }, 3000)
        }
    }

    function playVideo () {
        navigator.mediaDevices
            .getUserMedia({ video: videoConstraints })
            .then(stream => {
                let video = webcamRef.current;
                video.srcObject = stream;
                video.play();
                setVideoPlay(true)
            })
            .catch(err => {
                console.log(err);
                cansel()
            });
    }

    const videoStart = () => {
        playVideo()
        setVideoPlay(true)
    }

    const editPhoto = () => {
        const context = canvasRef.current.getContext('2d');
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setName('');
        setPhotoCaptured(false);
        setUserData(null);
        playVideo();
    }

    const videoStop = () => {
        webcamRef.current.pause();
        webcamRef.current.srcObject.getTracks()[0].stop();
    }

    function cansel() {
        setAddUser(false);
        setAddEmployee(false);
        setAddGuest(false);
        setAddWatch(false);
        setAddCompany(false);
        setAddLocation(false);
        setAddCamera(false);
        setUserData(null);
        setPhotoCaptured(false);
        if (videoPlay){
            setVideoPlay(false);
            videoStop();
        }
    }

    const backStep = () => {
        setAddUser(false);
        setAddEmployee(false);
        setAddGuest(false);
        setAddWatch(false);
        setAddCompany(false);
        setAddLocation(false);
        setAddCamera(false);
        cansel();
        navigate(-1);
    }

    const capture = React.useCallback(
        () => {
            const context = canvasRef.current.getContext('2d')
            // context.translate(canvasRef.current.width, 0);
            // context.scale(-1, 1);
            context.drawImage(webcamRef.current, 0, 0, webcamRef.current.width, webcamRef.current.height);
            const base64dataUrl = canvasRef.current.toDataURL('image/jpeg');
            context.setTransform(1, 0, 0, 1, 0, 0);
            setUserData(base64dataUrl);
            setPhotoCaptured(true);
            webcamRef.current.pause();
            webcamRef.current.srcObject.getTracks()[0].stop();
        },
        [webcamRef]
    );

    const saveFile = (categoryId) => {
        setLoader(true)
        try{
            faceMatcher(imgInput).then(descriptor => {
                if (descriptor){
                    const labeledDescriptors = [
                        new faceapi.LabeledFaceDescriptors(
                            name,
                            [descriptor]
                        )
                    ]
                    PeopleService.addPeople(
                        name,
                        userData,
                        JSON.stringify(labeledDescriptors),
                        companyId,
                        categoryId)
                        .then(() => navigate('/peoples'))
                        .catch(err => setError(err.response.data.message))
                }
            }).finally(() => setLoader(false))
        }  catch (err) {
            setError(err.response.data.message)
        }
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <Error>{error}</Error>
            }
            <Container>
                <div className="flex jc-center">
                    {
                        !addUser && !addEmployee && !addGuest && !addWatch && !addCompany && !addLocation && !addCamera
                        ?
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                type={'add'}
                                buttonText={'Далее'}
                                buttonBackText={'Отмена'}
                                next={nextStep}
                                back={backStep}
                            >
                                <Select value={select} onChange={event => setSelect(event.target.value)}>
                                    {
                                        user.isRole === 1 &&
                                            <>
                                                <option value="0">Пользователя</option>
                                                <option value="1">Сотрудника</option>
                                                <option value="2">Гостя</option>
                                                <option value="3">Сотрудника вахты</option>
                                                <option value="4">Компанию</option>
                                                <option value="5">Локацию</option>
                                                <option value="6">Камеру</option>
                                            </>
                                    }
                                    {
                                        user.isRole === 2 &&
                                        <>
                                            <option value="1">Сотрудника</option>
                                            <option value="2">Гостя</option>
                                            <option value="3">Сотрудника вахты</option>
                                            <option value="4">Компанию</option>
                                        </>
                                    }
                                    {
                                        user.isRole === 3 &&
                                        <>
                                            <option value="3">Сотрудника</option>
                                        </>
                                    }
                                    {
                                        user.isRole === 4 &&
                                        <>
                                            <option value="1">Сотрудника</option>
                                            <option value="2">Гостя</option>
                                            <option value="3">Сотрудника вахты</option>
                                            <option value="4">Компанию</option>
                                        </>
                                    }
                                </Select>
                            </Cart>
                        :
                            <>
                                {
                                    addUser && !addEmployee && !addGuest && !addWatch && !addCompany && !addLocation && !addCamera
                                    ?
                                        <Cart
                                            variant={'link'}
                                            variantBack={'outline'}
                                            type={'add'}
                                            buttonText={'Далее'}
                                            buttonBackText={'Отмена'}
                                            next={addUserInDb}
                                            back={backStep}
                                        >
                                            <h3 style={{marginBottom: '16px'}}>Добавить пользователя</h3>
                                            <Input placeholder={'Введите имя'} value={name} onChange={event => setName(event.target.value)}/>
                                            <Input placeholder={'Введите email'} value={email} onChange={event => setEmail(event.target.value)}/>
                                            <Select value={roleId} onChange={event => setRoleId(event.target.value)}>
                                                {
                                                    roles.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                }
                                            </Select>
                                            <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                {
                                                    companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                }
                                            </Select>
                                        </Cart>
                                    :
                                        <>
                                            {
                                                !addUser && !addEmployee && !addGuest && !addWatch && addCompany && !addLocation && !addCamera
                                                ?
                                                    <Cart
                                                        variant={'link'}
                                                        variantBack={'outline'}
                                                        type={'add'}
                                                        buttonText={'Далее'}
                                                        buttonBackText={'Отмена'}
                                                        next={addCompanyInDb}
                                                        back={backStep}
                                                    >
                                                        <h3 style={{marginBottom: '16px'}}>Добавить компанию</h3>
                                                        <Input placeholder={'Введите имя'} value={name} onChange={event => setName(event.target.value)}/>
                                                    </Cart>
                                                :
                                                    <>
                                                        {
                                                            !addUser && !addEmployee && !addGuest && !addWatch && !addCompany && addLocation && !addCamera
                                                            ?
                                                                <Cart
                                                                    variant={'link'}
                                                                    variantBack={'outline'}
                                                                    type={'add'}
                                                                    buttonText={'Далее'}
                                                                    buttonBackText={'Отмена'}
                                                                    next={addLocationInDb}
                                                                    back={backStep}
                                                                >
                                                                    <h3 style={{marginBottom: '16px'}}>Добавить локацию</h3>
                                                                    <Input placeholder={'Введите имя'} value={name} onChange={event => setName(event.target.value)}/>
                                                                </Cart>
                                                            :
                                                                <>
                                                                    {
                                                                        !addUser && !addEmployee && !addGuest && !addWatch && !addCompany && !addLocation && addCamera
                                                                            ?
                                                                                <Cart
                                                                                    variant={'link'}
                                                                                    variantBack={'outline'}
                                                                                    type={'add'}
                                                                                    buttonText={'Далее'}
                                                                                    buttonBackText={'Отмена'}
                                                                                    next={addCameraInDb}
                                                                                    back={backStep}
                                                                                >
                                                                                    <h3 style={{marginBottom: '16px'}}>Добавить камеру</h3>
                                                                                    <Input placeholder={'Введите название'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                    <Input placeholder={'Введите адрес реле'} value={rele} onChange={event => setRele(event.target.value)}/>
                                                                                    <Input placeholder={'Введите адрес камеры'} value={url} onChange={event => setUrl(event.target.value)}/>
                                                                                    <Input placeholder={'Введите разницу сопоставления'} value={accuracy} onChange={event => setAccuracy(event.target.value)}/>
                                                                                    <Select value={movement} onChange={event => setMovement(event.target.value)}>
                                                                                        <option value={'Вход'}>Вход</option>
                                                                                        <option value={'Выход'}>Выход</option>
                                                                                    </Select>
                                                                                    <Select value={locationId} onChange={event => setLocationId(event.target.value)}>
                                                                                        {
                                                                                            locations.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                        }
                                                                                    </Select>
                                                                                </Cart>
                                                                            :
                                                                                <>
                                                                                    {
                                                                                        !addUser && addEmployee && !addGuest && !addWatch && !addCompany && !addLocation && !addCamera
                                                                                        ?
                                                                                            !photoCaptured && !videoPlay
                                                                                            ?
                                                                                            <Cart
                                                                                                variantBack={'linkText'}
                                                                                                type={'add'}
                                                                                                buttonBackText={'Отмена'}
                                                                                                buttonText={'Назад'}
                                                                                                variant={'outline'}
                                                                                                next={cansel}
                                                                                                back={backStep}
                                                                                            >
                                                                                                <h3 style={{marginBottom: '16px'}}>Добавить сотрудника</h3>
                                                                                                <div className="fileInput">
                                                                                                    <label htmlFor="file1">
                                                                                                        <img src={process.env.PUBLIC_URL + '/assets/icons/folder.svg'} alt="Загрузить с устройства"/>
                                                                                                        Загрузить с устройства
                                                                                                    </label>
                                                                                                    <input ref={inputFileRef} onChange={loadFileInput} id='file1' type={'file'} />
                                                                                                </div>
                                                                                                <Button onClick={videoStart} icon={process.env.PUBLIC_URL + '/assets/icons/camera.svg'} variant={'icon'} style={{marginBottom: '16px'}}>Сделать снимок</Button>
                                                                                            </Cart>
                                                                                            :
                                                                                                <>
                                                                                                    {
                                                                                                        photoCaptured && !videoPlay
                                                                                                            ?
                                                                                                            !error &&
                                                                                                                <Cart
                                                                                                                    variantBack={'linkText'}
                                                                                                                    type={'add'}
                                                                                                                    buttonBackText={'Отмена'}
                                                                                                                    buttonText={'Назад'}
                                                                                                                    variant={'outline'}
                                                                                                                    next={cansel}
                                                                                                                    back={backStep}
                                                                                                                >
                                                                                                                    <h3 style={{marginBottom: '16px'}}>Добавить сотрудника</h3>
                                                                                                                    <img ref={imgInput} style={{marginBottom: '16px', width: '100%', maxWidth: '100%'}} src={userData} alt="Загрузить с устройства"/>
                                                                                                                    <Input placeholder={'Ведите ФИО'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                                                    <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                                                                                        {
                                                                                                                            companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                                        }
                                                                                                                    </Select>
                                                                                                                    {
                                                                                                                        !error && <Button onClick={() => saveFile(1)} variant={'link'} style={{marginBottom: '16px'}}>Добавить</Button>
                                                                                                                    }
                                                                                                                </Cart>
                                                                                                            :
                                                                                                                !error &&
                                                                                                                    <Cart
                                                                                                                        variantBack={'linkText'}
                                                                                                                        type={'add'}
                                                                                                                        buttonBackText={'Отмена'}
                                                                                                                        buttonText={photoCaptured ? 'Переделать фото' : 'Назад'}
                                                                                                                        variant={'outline'}
                                                                                                                        next={photoCaptured ? editPhoto : cansel}
                                                                                                                        back={backStep}
                                                                                                                    >
                                                                                                                        <h3 style={{marginBottom: '16px'}}>Добавить сотрудника</h3>
                                                                                                                        <video style={{width: '100%', maxWidth: '100%', marginBottom: '16px'}} ref={webcamRef} width={videoConstraints.width} height={videoConstraints.height} ></video>
                                                                                                                        <canvas width={videoConstraints.width} height={videoConstraints.height} ref={canvasRef} style={{ position: 'absolute', left: '15px', top: '78px', maxWidth: '338.33px', zIndex: 1}} />
                                                                                                                        {
                                                                                                                            photoCaptured
                                                                                                                            ?
                                                                                                                                <>
                                                                                                                                    <img ref={imgInput} style={{display: 'none'}} src={userData} alt="Загрузить с устройства"/>
                                                                                                                                    <Input placeholder={'Ведите ФИО'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                                                                    <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                                                                                                        {
                                                                                                                                            companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                                                        }
                                                                                                                                    </Select>
                                                                                                                                    {
                                                                                                                                        !error && <Button onClick={() => saveFile(1)} variant={'link'} style={{marginBottom: '16px'}}>Добавить</Button>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            :
                                                                                                                                <Button onClick={capture} variant={'link'} style={{marginBottom: '16px'}}>Сделать снимок</Button>
                                                                                                                        }
                                                                                                                    </Cart>
                                                                                                    }
                                                                                                </>

                                                                                        :
                                                                                            <>
                                                                                                {
                                                                                                    !addUser && !addEmployee && addGuest && !addWatch && !addCompany && !addLocation && !addCamera
                                                                                                    ?
                                                                                                        !photoCaptured && !videoPlay
                                                                                                            ?
                                                                                                            <Cart
                                                                                                                variantBack={'linkText'}
                                                                                                                type={'add'}
                                                                                                                buttonBackText={'Отмена'}
                                                                                                                buttonText={'Назад'}
                                                                                                                variant={'outline'}
                                                                                                                next={cansel}
                                                                                                                back={backStep}
                                                                                                            >
                                                                                                                <h3 style={{marginBottom: '16px'}}>Добавить гостя</h3>
                                                                                                                <div className="fileInput">
                                                                                                                    <label htmlFor="file1">
                                                                                                                        <img src={process.env.PUBLIC_URL + '/assets/icons/folder.svg'} alt="Загрузить с устройства"/>
                                                                                                                        Загрузить с устройства
                                                                                                                    </label>
                                                                                                                    <input ref={inputFileRef} onChange={loadFileInput} id='file1' type={'file'} />
                                                                                                                </div>
                                                                                                                <Button onClick={videoStart} icon={process.env.PUBLIC_URL + '/assets/icons/camera.svg'} variant={'icon'} style={{marginBottom: '16px'}}>Сделать снимок</Button>
                                                                                                            </Cart>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    photoCaptured && !videoPlay
                                                                                                                    ?
                                                                                                                        !error &&
                                                                                                                            <Cart
                                                                                                                                variantBack={'linkText'}
                                                                                                                                type={'add'}
                                                                                                                                buttonBackText={'Отмена'}
                                                                                                                                buttonText={'Назад'}
                                                                                                                                variant={'outline'}
                                                                                                                                next={cansel}
                                                                                                                                back={backStep}
                                                                                                                            >
                                                                                                                                <h3 style={{marginBottom: '16px'}}>Добавить гостя</h3>
                                                                                                                                <img ref={imgInput} style={{marginBottom: '16px', width: '100%', maxWidth: '100%'}} src={userData} alt="Загрузить с устройства"/>
                                                                                                                                <Input placeholder={'Ведите ФИО'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                                                                <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                                                                                                    {
                                                                                                                                        companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                                                    }
                                                                                                                                </Select>
                                                                                                                                {
                                                                                                                                    !error && <Button onClick={() => saveFile(3)} variant={'link'} style={{marginBottom: '16px'}}>Добавить</Button>
                                                                                                                                }
                                                                                                                            </Cart>
                                                                                                                    :
                                                                                                                        !error &&
                                                                                                                            <Cart
                                                                                                                                variantBack={'linkText'}
                                                                                                                                type={'add'}
                                                                                                                                buttonBackText={'Отмена'}
                                                                                                                                buttonText={photoCaptured ? 'Переделать фото' : 'Назад'}
                                                                                                                                variant={'outline'}
                                                                                                                                next={photoCaptured ? editPhoto : cansel}
                                                                                                                                back={backStep}
                                                                                                                            >
                                                                                                                                <h3 style={{marginBottom: '16px'}}>Добавить гостя</h3>
                                                                                                                                <video style={{width: '100%', maxWidth: '100%', marginBottom: '16px'}} ref={webcamRef} width={videoConstraints.width} height={videoConstraints.height} ></video>
                                                                                                                                <canvas width={videoConstraints.width} height={videoConstraints.height} ref={canvasRef} style={{ position: 'absolute', left: '15px', top: '78px', maxWidth: '338.33px', zIndex: 1}} />
                                                                                                                                {
                                                                                                                                    photoCaptured
                                                                                                                                        ?
                                                                                                                                        <>
                                                                                                                                            <img ref={imgInput} style={{display: 'none'}} src={userData} alt="Загрузить с устройства"/>
                                                                                                                                            <Input placeholder={'Ведите ФИО'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                                                                            <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                                                                                                                {
                                                                                                                                                    companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                                                                }
                                                                                                                                            </Select>
                                                                                                                                            {
                                                                                                                                                !error && <Button onClick={() => saveFile(3)} variant={'link'} style={{marginBottom: '16px'}}>Добавить</Button>
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        <Button onClick={capture} variant={'link'} style={{marginBottom: '16px'}}>Сделать снимок</Button>
                                                                                                                                }
                                                                                                                            </Cart>
                                                                                                                }
                                                                                                            </>
                                                                                                    :
                                                                                                        <>
                                                                                                            {
                                                                                                                !addUser && !addEmployee && !addGuest && addWatch && !addCompany && !addLocation && !addCamera
                                                                                                                    &&
                                                                                                                !photoCaptured && !videoPlay
                                                                                                                    ?
                                                                                                                    <Cart
                                                                                                                        variantBack={'linkText'}
                                                                                                                        type={'add'}
                                                                                                                        buttonBackText={'Отмена'}
                                                                                                                        buttonText={'Назад'}
                                                                                                                        variant={'outline'}
                                                                                                                        next={cansel}
                                                                                                                        back={backStep}
                                                                                                                    >
                                                                                                                        <h3 style={{marginBottom: '16px'}}>{user.isRole === 3 ? 'Добавить сотрудника' : 'Добавить сотрудника вахты'}</h3>
                                                                                                                        <div className="fileInput">
                                                                                                                            <label htmlFor="file1">
                                                                                                                                <img src={process.env.PUBLIC_URL + '/assets/icons/folder.svg'} alt="Загрузить с устройства"/>
                                                                                                                                Загрузить с устройства
                                                                                                                            </label>
                                                                                                                            <input ref={inputFileRef} onChange={loadFileInput} id='file1' type={'file'} />
                                                                                                                        </div>
                                                                                                                        <Button onClick={videoStart} icon={process.env.PUBLIC_URL + '/assets/icons/camera.svg'} variant={'icon'} style={{marginBottom: '16px'}}>Сделать снимок</Button>
                                                                                                                    </Cart>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            photoCaptured && !videoPlay
                                                                                                                                ?
                                                                                                                                    !error &&
                                                                                                                                        <Cart
                                                                                                                                            variantBack={'linkText'}
                                                                                                                                            type={'add'}
                                                                                                                                            buttonBackText={'Отмена'}
                                                                                                                                            buttonText={'Назад'}
                                                                                                                                            variant={'outline'}
                                                                                                                                            next={cansel}
                                                                                                                                            back={backStep}
                                                                                                                                        >
                                                                                                                                            <h3 style={{marginBottom: '16px'}}>{user.isRole === 3 ? 'Добавить сотрудника' : 'Добавить сотрудника вахты'}</h3>
                                                                                                                                            <img ref={imgInput} style={{marginBottom: '16px', width: '100%', maxWidth: '100%'}} src={userData} alt="Загрузить с устройства"/>
                                                                                                                                            <Input placeholder={'Ведите ФИО'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                                                                            {
                                                                                                                                                user.isRole === 3
                                                                                                                                                    ?
                                                                                                                                                    <Input value={companyName} disabled/>
                                                                                                                                                    :
                                                                                                                                                    <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                                                                                                                        {
                                                                                                                                                            companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                                                                        }
                                                                                                                                                    </Select>
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                !error && <Button onClick={() => saveFile(2)} variant={'link'} style={{marginBottom: '16px'}}>Добавить</Button>
                                                                                                                                            }
                                                                                                                                        </Cart>
                                                                                                                                :
                                                                                                                                    !error &&
                                                                                                                                        <Cart
                                                                                                                                            variantBack={'linkText'}
                                                                                                                                            type={'add'}
                                                                                                                                            buttonBackText={'Отмена'}
                                                                                                                                            buttonText={photoCaptured ? 'Переделать фото' : 'Назад'}
                                                                                                                                            variant={'outline'}
                                                                                                                                            next={photoCaptured ? editPhoto : cansel}
                                                                                                                                            back={backStep}
                                                                                                                                        >
                                                                                                                                            <h3 style={{marginBottom: '16px'}}>{user.isRole === 3 ? 'Добавить сотрудника' : 'Добавить сотрудника вахты'}</h3>
                                                                                                                                            <video style={{width: '100%', maxWidth: '100%', marginBottom: '16px'}} ref={webcamRef} width={videoConstraints.width} height={videoConstraints.height} ></video>
                                                                                                                                            <canvas width={videoConstraints.width} height={videoConstraints.height} ref={canvasRef} style={{ position: 'absolute', left: '15px', top: '78px', maxWidth: '338.33px', zIndex: 1}} />
                                                                                                                                            {
                                                                                                                                                photoCaptured
                                                                                                                                                    ?
                                                                                                                                                    <>
                                                                                                                                                        <img ref={imgInput} style={{display: 'none'}} src={userData} alt="Загрузить с устройства"/>
                                                                                                                                                        <Input placeholder={'Ведите ФИО'} value={name} onChange={event => setName(event.target.value)}/>
                                                                                                                                                        {
                                                                                                                                                            user.isRole === 3
                                                                                                                                                                ?
                                                                                                                                                                <Input value={companyName} disabled/>
                                                                                                                                                                :
                                                                                                                                                                <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                                                                                                                                                    {
                                                                                                                                                                        companies.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                                                                                    }
                                                                                                                                                                </Select>
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            !error && <Button onClick={() => saveFile(2)} variant={'link'} style={{marginBottom: '16px'}}>Добавить</Button>
                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                    :
                                                                                                                                                    <Button onClick={capture} variant={'link'} style={{marginBottom: '16px'}}>Сделать снимок</Button>
                                                                                                                                            }
                                                                                                                                        </Cart>

                                                                                                                        }
                                                                                                                    </>
                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
        </div>
    );
});

export default AddComponent;