import {$authHost} from "../http/axios";

export default class PeopleService{
    static getPeoples = async (categoryId, page, limit) => {
        const {data} = await $authHost.get('/people', {
            params: {
                categoryId: categoryId,
                page: page,
                limit: limit
            }
        });
        return data;
    }

    static getPeople = async (id) => {
        const {data} = await $authHost.get(`/people/${id}`);
        return data;
    }

    static getPeopleByName = async (name) => {
        const {data} = await $authHost.get(`/people/getName`, {
            params: {
                name: name
            }
        });
        return data;
    }

    static getUsersByCompany = async (companyId) => {
        const {data} = await $authHost.get(`/people/company/${companyId}`);
        return data
    }

    static addPeople = async (name, imgBase64, encodeArray, companyId, categoryId) => {
        const {data} = await $authHost.post('/people', {name, imgBase64, encodeArray, companyId, categoryId});
        return data;
    }

    static editPeople = async (id, name, companyId, categoryId) => {
        const {data} = await $authHost.put(`/people/${id}`, {name, companyId, categoryId});
        return data;
    }

    static removePeople = async (id) => {
        const {data} = await $authHost.delete(`/people/${id}`);
        return data;
    }
}