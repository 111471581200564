import React from 'react';
import {Context} from "../index";
import PeopleService from "../services/peopleService";
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import BottomMenu from "../ui/menu/bottomMenu";
import Popup from "../ui/popup/popup";
import Button from "../ui/button/button";
import Bar from "../ui/bar/bar";
import Select from "../ui/select/select";
import CategoryService from "../services/categoryService";
import {observer} from "mobx-react-lite";
import jwt_decode from "jwt-decode";
import Moment from 'react-moment';

const PeopleComponent = observer(() => {
    const {people, category} = React.useContext(Context);

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [popup, setPopup] = React.useState(false);
    const [dataPopup, setDataPopup] = React.useState('');
    const [categoryId, setCategoryId] = React.useState('0');

    const loadData = async (categoryId) => {
        const [peopleResult, categoryResult] = await Promise.allSettled([
            PeopleService.getPeoples(categoryId),
            CategoryService.getCategories()]);
        people.setPeoples(peopleResult.value);
        category.setCategories(categoryResult.value)
    }

    const loadDataByCompany = async () => {
        const [peopleResult, categoryResult] = await Promise.allSettled([
            PeopleService.getUsersByCompany(jwt_decode(localStorage.getItem('token')).companyId),
            CategoryService.getCategories()]);
        people.setPeoples(peopleResult.value);
        category.setCategories(categoryResult.value)
    }

    React.useEffect(() => {
        try{
            if (jwt_decode(localStorage.getItem('token')).roleId !== 3){
                loadData(categoryId)
                    .then()
                    .catch(err => setError(err.response.data.message))
                    .finally(() => setLoader(false))
            } else {
                loadDataByCompany()
                    .then()
                    .catch(err => setError(err.response.data.message))
                    .finally(() => setLoader(false))
            }
        } catch (err) {
            setError(err.response.data.message);
        }
    }, [categoryId]);

    const openPopup = (data) => {
        setPopup(true);
        setDataPopup(data);
    }

    const closePopup = () => {
        setPopup(false);
        setDataPopup('')
    }

    const clearFilter = () => {
        setCategoryId('0')
    }

    const getPeoples = event => {
        setCategoryId(event.target.value)
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Bar>
                {
                    jwt_decode(localStorage.getItem('token')).roleId !== 3
                    ?
                        <>
                            <Select value={categoryId} onChange={getPeoples}>
                                <option value={'0'}>Все</option>
                                {
                                    category.isCategories?.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                }
                            </Select>
                            <Button onClick={clearFilter} variant={'outline'}>Очистить фильтр</Button>
                        </>
                    :
                        <>
                        </>
                }

            </Bar>
            <Container
                variant={'leftBar'}
            >ß
                <div className="flex fw-wrap">
                    {
                        people.isPeoples.rows?.slice().reverse().map(
                            item =>
                                <Cart
                                    key={item.id}
                                    variant={'link'}
                                    type={'button'}
                                    buttonText={'Перейти'}
                                    to={'/peoples/' + item.id}
                                >
                                    <div className="name">
                                        <span>Фото:</span>
                                        <img
                                            src={process.env.REACT_APP_API_URL_STATIC + `/img/${item.company.name}/${item.img}`}
                                            alt={item.name}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'cover',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => openPopup(process.env.REACT_APP_API_URL_STATIC + `/img/${item.company.name}/${item.img}`)}
                                        />
                                    </div>
                                    <div className="name">
                                        <span>Имя:</span>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="company">
                                        <span>Компания:</span>
                                        <p>{item.company.name}</p>
                                    </div>
                                    <div className="role">
                                        <span>Категория:</span>
                                        <p>{item.category.name}</p>
                                    </div>
                                    <div className="role">
                                        <span>Добавлен(а):</span>
                                        <p><Moment format={'YYYY.MM.DD HH:mm:ss'}>{item.createdAt}</Moment></p>
                                    </div>
                                </Cart>
                        )
                    }
                    {
                        people.isPeoples.count === 0 && <p style={{padding: '8px'}}>Нет данных для отображения</p>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count">
                    <span>Всего: </span><p>{people.isPeoples.count}</p>
                </div>
                <div className="addButton"></div>
            </BottomMenu>
            {
                popup &&
                    <Popup>
                        <div style={{marginTop: '72px', flexDirection: 'column'}} className='flex jc-center ai-center'>
                            <Cart>
                                <img style={{width: '100%', height: 'auto', objectFit: 'cover'}} src={dataPopup} alt=""/>
                                <Button onClick={closePopup} style={{width: '100%', marginTop: '16px'}} variant={'linkText'}>Закрыть</Button>
                            </Cart>
                        </div>
                    </Popup>
            }
        </div>
    );
});

export default PeopleComponent;