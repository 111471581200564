import React from 'react';
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import Input from "../ui/input/input";
import Hr from "../ui/hr/hr";
import UserService from "../services/userService";
import Loader from "../ui/loader/loader";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

const LoginComponent = observer(() => {
    const {user} = React.useContext(Context);
    const [error, setError] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const navigate = useNavigate();

    React.useEffect(() => {
        UserService.check()
            .then(data => {
                user.setIsAuth(true)
                user.setRole(data.roleId)
                navigate('/')
            })
            .catch(() => {
                user.setIsAuth(false)
                user.setRole(0)
            })
            .finally(() => setLoader(false))
    }, [navigate, user])

    const login = async () => {
        try{
            setLoader(true)
            UserService.login(email, password)
                .then(data => {
                    user.setIsAuth(true)
                    user.setRole(data.roleId)
                    if (data.roleId === 3){
                        navigate('/peoples')
                    } else if (data.roleId === 5) {
                        navigate('/cameras')
                    } else {
                        navigate('/')
                    }
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoader(false))
        } catch (err) {
            setError(err.response.data.message)
        }
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex jc-center">
                    <Cart
                        variant={'link'}
                        variantBack={'outline'}
                        type={'add'}
                        buttonText={'Изменить'}
                        buttonBackText={'Войти'}
                        back={login}
                    >
                        <h2>Авторизация</h2>
                        <Input value={email} onChange={event => setEmail(event.target.value)} type={'email'} placeholder='Email'/>
                        <Input value={password} onChange={event => setPassword(event.target.value)} type={'password'} placeholder='Пароль'/>
                        <Hr/>
                        <p>Данные для авторизации ищите на Email, который вы давали администратору.</p>
                    </Cart>
                </div>
            </Container>
        </div>
    );
});

export default LoginComponent;