import DashboardPage from "../pages/dashboardPage";
import UserPage from "../pages/userPage";
import JournalPage from "../pages/journalPage";
import PeoplePage from "../pages/peoplePage";
import LoginPage from "../pages/loginPage";
import CameraPage from "../pages/cameraPage";
import LocationPage from "../pages/locationPage";
import CategoryPage from "../pages/categoryPage";
import RolePage from "../pages/rolePage";
import CompanyPage from "../pages/companyPage";
import ProfilePage from "../pages/profilePage";
import AddPage from "../pages/addPage";
import UserOnePage from "../pages/userOnePage";
import PeopleOnePage from "../pages/peopleOnePage";
import CompanyOnePage from "../pages/companyOnePage";
import LocationOnePage from "../pages/locationOnePage";
import CameraOnePage from "../pages/cameraOnePage";
import ErrorPage from "../pages/errorPage";
import CameraStreamOnePage from "../pages/cameraStreamOnePage";
import {createBrowserRouter} from "react-router-dom";
import jwt_decode from "jwt-decode";

let routes;
const roleId = jwt_decode(localStorage.getItem('token')).roleId;

if (roleId === 1){
    routes = [
        // {path: '/', element: <DashboardPage />},
        {path: '/login', element: <LoginPage />},
        {path: '/users', element: <UserPage />},
        {path: '/users/:id', element: <UserOnePage />},
        {path: '/', element: <PeoplePage />},
        {path: '/:id', element: <PeopleOnePage />,},
        {path: '/companies', element: <CompanyPage />},
        {path: '/companies/:id', element: <CompanyOnePage />},
        {path: '/profile', element: <ProfilePage />},
        {path: '/locations', element: <LocationPage />},
        {path: '/locations/:id', element: <LocationOnePage />},
        {path: '/cameras', element: <CameraPage />},
        {path: '/cameras/:id', element: <CameraOnePage />},
        {path: '/camera/:url', element: <CameraStreamOnePage />},
        {path: '/categories', element: <CategoryPage />},
        {path: '/roles', element: <RolePage />},
        {path: '/journals', element: <JournalPage />},
        {path: '/add', element: <AddPage />},
        {path: '/logout'},
        {path: '*', element: <ErrorPage />},
    ]
}

if (roleId === 2){
    routes = [
        {path: '/login', element: <LoginPage />},
        {path: '/', element: <PeoplePage />},
        {path: '/:id', element: <PeopleOnePage />,},
        {path: '/profile', element: <ProfilePage />},
        {path: '/journals', element: <JournalPage />},
        {path: '/add', element: <AddPage />},
        {path: '/logout'},
        {path: '*', element: <ErrorPage />},
    ]
}

if (roleId === 3){
    routes = [
        // {path: '/login', element: <LoginPage />},
        {path: '/', element: <PeoplePage />},
        {path: '/:id', element: <PeopleOnePage />,},
        {path: '/profile', element: <ProfilePage />},
        {path: '/add', element: <AddPage />},
        {path: '/logout'},
        {path: '*', element: <ErrorPage />},
    ]
}

if (roleId === 4){
    routes = [
        // {path: '/', element: <DashboardPage />},
        {path: '/login', element: <LoginPage />},
        {path: '/', element: <PeoplePage />},
        {path: '/:id', element: <PeopleOnePage />,},
        {path: '/profile', element: <ProfilePage />},
        {path: '/add', element: <AddPage />},
        {path: '/logout'},
        {path: '*', element: <ErrorPage />},
    ]
}

if (roleId === 5){
    routes = [
        {path: '/login', element: <LoginPage />},
        {path: '/cameras', element: <CameraPage />},
        {path: '/cameras/:id', element: <CameraOnePage />},
        {path: '/camera/:url', element: <CameraStreamOnePage />},
        {path: '*', element: <ErrorPage />},
    ]
}

export const router = createBrowserRouter(routes, {
    future: {
        v7_normalizeFormMethod: true,
    },
})

