import React from 'react';
import {Context} from "../index";
import JournalService from "../services/journalService";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import BottomMenu from "../ui/menu/bottomMenu";
import Bar from "../ui/bar/bar";
import {observer} from "mobx-react-lite";
import Moment from "react-moment";
import {getCountPages} from "../utils/page";

const JournalComponent = observer(() => {
    const {journal} = React.useContext(Context);

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [datas, setDatas] = React.useState([]);
    const [pages, setPages] = React.useState(0);
    const lastRef = React.useRef(null);
    const intersectionObserver = React.useRef(null);

    const loadMore = () => {
        setLoader(true)
        let nexPage = currentPage + 1
        setCurrentPage(nexPage)
        JournalService.getJournals(nexPage, Number(process.env.REACT_APP_LIMIT_CART_ON_PAGE))
            .then(data => {
                setDatas([...datas, ...data.rows])
            })
            .catch(err => setError(err.response.data.message))
            .finally(() => {
                setLoader(false);
            })
    }

    React.useEffect(() => {
        JournalService.getJournals(currentPage, Number(process.env.REACT_APP_LIMIT_CART_ON_PAGE))
            .then(data => {
                journal.setJournals(data);
                setPages(getCountPages(data.count, Number(process.env.REACT_APP_LIMIT_CART_ON_PAGE)))
                setDatas(data.rows)
            })
            .catch(err => {
                setError(err.response.data.message);
            })
            .finally(() => {
                setLoader(false);
            })
    }, [journal]);

    React.useEffect(() => {
        if(loader) return
        if(intersectionObserver.current) intersectionObserver.current.disconnect()
        let callback = function (entries, observer){
            if (entries[0].isIntersecting && currentPage < pages){
                loadMore()
            }
        }
        intersectionObserver.current = new IntersectionObserver(callback);
        intersectionObserver.current.observe(lastRef.current);
    }, [loader])

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Bar>

            </Bar>
            <Container
                variant={'leftBar'}
            >
                <div className="flex fw-wrap">
                    {
                        datas.map(
                            item =>
                                <Cart
                                    key={item.id}
                                >
                                    <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
                                        <img width={100} height={100} src={process.env.REACT_APP_API_URL_STATIC + `/img/${item.company}/${item.img}`} alt={item.img} style={{objectFit: 'cover'}}/>
                                        <span>=</span>
                                        <img width={100} height={100} src={process.env.REACT_APP_API_URL_STATIC + `/img/checked/${item.imgCheck}`} alt={item.imgCheck} style={{objectFit: 'cover'}}/>
                                    </div>
                                    <div className="name">
                                        <span>Имя:</span>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="name">
                                        <span>Движение:</span>
                                        <p>{item.movement}</p>
                                    </div>
                                    <div className="name">
                                        <span>Дата:</span>
                                        <p><Moment format={'YYYY.MM.DD HH:mm:ss'}>{item.createdAt}</Moment></p>
                                    </div>
                                </Cart>
                        )
                    }
                    {
                        journal.isJournals.count === 0 && <p style={{padding: '8px'}}>Нет данных для отображения</p>
                    }
                </div>
                <div ref={lastRef} style={{height: '1px'}}></div>
            </Container>
            <BottomMenu>
                <div className="count">
                    <span>Записей: </span><p style={{marginRight: '12px'}}>{journal.isJournals.count}</p>
                    |
                    <span style={{marginLeft: '12px'}}>Страница: </span><p>{currentPage} из {pages}</p>
                </div>
                <div className="addButton"></div>
            </BottomMenu>
        </div>
    );
});

export default JournalComponent;