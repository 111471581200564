import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import UserStore from "./store/userStore";
import RoleStore from "./store/roleStore";
import PeopleStore from "./store/peopleStore";
import LocationStore from "./store/locationStore";
import JournalStore from "./store/journalStore";
import CompanyStore from "./store/companyStore";
import CameraStore from "./store/cameraStore";
import CategoryStore from "./store/categoryStore";
import {router} from "./routes";


export const Context = React.createContext(null);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{
        user: new UserStore(),
        role: new RoleStore(),
        category: new CategoryStore(),
        company: new CompanyStore(),
        location: new LocationStore(),
        people: new PeopleStore(),
        journal: new JournalStore(),
        camera: new CameraStore(),
    }}>
        <RouterProvider router={router} />
    </Context.Provider>
);
